import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { unavailableTime } from '@/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: configRoutes()
})

function configRoutes () {
  return [{
    path: '/',
    name: 'Main',
    // redirect: 'member/main',
    redirect: '/',
    component: () => import('@/pages/Main.vue'),
    children: [{
      path: 'member/main',
      name: 'hotgame',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/',
      name: 'hotgame',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/member/welcome',
      name: 'hotgame',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/play_lottery',
      name: 'hotgame',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: '/authenticate',
      name: 'hotgame',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'casino',
      name: 'casino',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'visual_sport',
      name: 'visual_sport',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'slot',
      name: 'slot',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'slot/:banner',
      name: 'games/slot',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'sport',
      name: 'sport',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'fishing',
      name: 'fishing',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'promotions',
      name: 'promotions',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'lotto',
      name: 'lotto',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      // Tg v4.0
      // component: () => import('@/pages/huayPage/huayPage.vue')
      // Tg v3.0
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    // {
    //   path: 'welcome',
    //   name: 'welcome',
    //   meta: {
    //     requiresAuth: false,
    //     isLottoPage: false,
    //     isGamePage: false
    //   },
    //   component: () => import('@/pages/welcomePage/welcomePage.vue')
    // },
    {
      path: 'welcome',
      name: 'hotgame',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'hotgame',
      name: 'hotgame',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'login',
      name: 'welcome',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/welcomePage/welcomePage.vue')
    },
    {
      path: 'huay',
      name: 'huay',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/huayPage/huayPage.vue')
    },
    {
      path: 'lotto/:lottoType',
      name: 'lotto/:lottoType',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/huayPage/betPage.vue')
    },
    {
      path: 'setting/profile',
      name: 'profile',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/settingsProfilePage/settingsProfilePage.vue')
    },
    {
      path: 'forgetpassword',
      name: 'forgot-password',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/forgotPage/forgotPage.vue')
    },
    // {
    //   path: 'member/lottery',
    //   name: 'lottery',
    //   meta: {
    //     requiresAuth: true,
    //     isLottoPage: true,
    //     isGamePage: false
    //   },
    //   component: () => import('@/pages/lotteryPage/lotteryPage.vue')
    // },
    {
      path: 'member/lottery',
      name: 'lotto',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'member/result/all',
      name: 'result',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/resultPage/resultPage.vue')
    },
    // {
    //   path: 'member/lottery/lottoset',
    //   name: 'lottolist',
    //   meta: {
    //     requiresAuth: true,
    //     isLottoPage: false,
    //     isGamePage: false
    //   },
    //   component: () => import('@/pages/lottoSetPage/lottoSetPage.vue')
    // },
    {
      path: 'member/lottery/lottoset',
      name: 'lotto',
      meta: {
        requiresAuth: null,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/mainPage/mainPage.vue')
    },
    {
      path: 'member/lottery/lottoset/:id',
      name: 'lottoset/:id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoSetPage/numberSetPage/numberSetPage.vue')
    },
    // For lottery result
    {
      path: 'member/lottery/result/:id',
      name: 'lottoticket/:id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoTicketPage/numberTicketPage/numberTicketPage.vue')
    },
    {
      path: 'member/lottery/lottoset/:id/tang',
      name: 'lottoset/:id/tang',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lottoSetPage/numberSetTangPage/numberSetTangPage.vue')
    },
    {
      path: 'member/lottery/lottoset/:id/poy',
      name: 'lottoset/:id/tang',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lottoSetPage/numberSetPoyPage/numberSetPoyPage.vue')
    },
    // For lottery poy
    {
      path: 'member/lottery/poy/:id/',
      name: 'lottoset/poy/:id',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lottoTicketPage/numberTicketPoyPage/numberTicketPoyPage.vue')
    },
    {
      path: 'member/settings/bank/add',
      name: 'bankbook',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      // component: () => import('@/pages/bankBookPage/bankBookPage.vue')
      component: () => import('@/pages/bankBookPage/bankBookPage2.vue')
    },
    {
      path: 'member/settings/bank2/add',
      name: 'bankbook2',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/bankBookPage/bankBookPage.vue')
      // component: () => import('@/pages/bankBookPage/bankBookPage2.vue')
    },
    {
      path: 'member/request/all',
      name: 'memberrequest',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/requestCashPage/requestCashPage.vue')
    },
    {
      path: 'member/statement',
      name: 'statement',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/statementPage/statementPage.vue')
    },
    {
      path: 'member/games',
      name: 'games',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/index.vue')
    },
    {
      path: 'member/games/casino',
      name: 'games/casino',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/casino.vue')
    },
    {
      path: 'member/games/fisher',
      name: 'games/fisher',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/fisher.vue')
    },
    {
      path: 'member/games/sport',
      name: 'games/sport',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/sport.vue')
    },
    {
      path: 'member/games/slot/:banner',
      name: 'games/slot',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: true
      },
      component: () => import('@/pages/games/slot.vue')
    },
    {
      path: 'member/games/history',
      name: 'games/history',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/games/history.vue')
    },
    {
      path: 'member/numbersets',
      name: 'numbersets',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/numberSetPage/numberSetPage.vue')
    },
    {
      path: 'member/numbersets/detail/:id',
      name: 'numbersets/detail',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/numberSetDetail/numberSetDetail.vue')
    },
    {
      path: 'member/numbersets/add',
      name: 'numberSetAddPage',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/numberSetAddPage/numberSetAddPage.vue')
    },
    {
      path: 'member/poy',
      name: 'poy',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/poyPage/poyPage.vue')
    },
    {
      path: 'member/poy/result/:id',
      name: 'poy/result',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/poyPage/poyResultPage.vue')
    },
    {
      path: 'member/lottery/government',
      name: 'lottery-government',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/govermentLottoPage/govermentLottoPage.vue')
    },
    {
      path: 'member/lottery/:id',
      name: 'lottery-by-id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      // component: () => import('@/pages/lottoryBetPage/lottoBetPage.vue')
      component: () => import('@/pages/lottoTicketPage/numberTicketTangPage/numberTicketTangPage.vue')
    },
    {
      path: 'member/lottery/lotto/:id',
      name: 'lottery-government-by-id',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoBetPage/lottoBetPage.vue')
    },
    {
      path: 'member/lottery/lotto/result/:id',
      name: 'lotto-lottery-result',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/lottoResultPage/lottoResultPage.vue')
    },
    {
      path: 'member/lottery/yeeki/:catalog',
      name: 'member-lottery-yeekee',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/yeekeeLotteryPage/yeekeeLotteryPage.vue')
    },
    {
      path: 'member/lottery/yeeki/:catalog/:id',
      name: 'yeekee-lottery-by-bet',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoBetYeekiPage/lottoBetYeekiPage.vue')
    },
    {
      path: 'member/lottery/yeeki-fire/:catalog/:id',
      name: 'yeekee-lottery-by-bet-fire',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lottoBetYeekiPage/lottoBetYeekiFirePage/lottoBetYeekiFirePage.vue')

    },
    {
      path: 'member/lottery/yeeki-result/:catalog/:id',
      name: 'yeekee-lottery-result',
      meta: {
        requiresAuth: true,
        isLottoPage: true,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/yeekeeLotteryPage/yeekeeResultPage/yeekeeResultPage.vue')
    },
    {
      path: 'member/lottery/yeeki-result/:catalog/:id/:page',
      name: 'yeekee-lottery-result',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/lotteryPage/yeekeeLotteryPage/yeekeeResultPage/yeekeeResultPage.vue')
    },
    {
      path: 'member/affiliate',
      name: 'affiliate',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/affiliatePage/affiliatePage.vue')
    },
    {
      path: 'member/affiliate/members',
      name: 'affiliate/members',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/affiliatePage/affiliateMemberPage.vue')
    },
    {
      path: 'member/affiliate/revenue',
      name: 'affiliate/revenue',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      // TODO: VUE_APP_CASINO_ENABLE
      // component: () => import('@/pages/affiliatePage/affiliateRevenue_main.vue')
      component: () => import('@/pages/affiliatePage/affiliateRevenue.vue')
    },
    {
      path: 'member/affiliate/withdraw',
      name: 'affiliate/withdraw',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/affiliatePage/affiliateWithdrawPage.vue')
    },
    {
      path: 'member',
      name: 'member',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      redirect: 'member/main'
    },
    // {
    //   path: 'register-verify/:id',
    //   name: 'register-verify',
    //   meta: {
    //     requiresAuth: false,
    //     isLottoPage: false,
    //     isGamePage: false,
    //     isNoRequestFooter: true
    //   },
    //   component: () => import('@/pages/registerPage/registerPage.vue')
    // },
    // {
    //   path: 'register-verify',
    //   name: 'register-verify',
    //   meta: {
    //     requiresAuth: false,
    //     isLottoPage: false,
    //     isGamePage: false,
    //     isNoRequestFooter: true
    //   },
    //   component: () => import('@/pages/registerPage/registerPage.vue')
    // },
    {
      path: 'aff/:userAg',
      name: 'aff/agent',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      // component: () => import('@/pages/registerAgentPage/registerAgPage.vue')
      component: () => import('@/pages/registerPage/registerForm.vue')
    },
    {
      path: 'register-verify/:id',
      name: 'register-verify',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerPage/registerForm.vue')
    },
    {
      path: 'register-verify',
      name: 'register-verify',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerPage/registerForm.vue')
    },
    {
      path: 'register-form/agent/:userAg',
      name: 'register-form/agent',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerAgentPage/registerAgForm.vue')
    },
    {
      path: 'register-success',
      name: 'register-success',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false,
        isNoRequestFooter: true
      },
      component: () => import('@/pages/registerPage/registerSuccess.vue')
    },
    {
      path: 'member/deposit',
      name: 'member/deposit',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => unavailableTime() ? import('@/pages/unavailablePage/unavailablePage.vue') : import('@/pages/depositPage/depositPage.vue')
    },
    {
      path: 'member/depositP',
      name: 'member/deposit',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => unavailableTime() ? import('@/pages/unavailablePage/unavailablePage.vue') : import('@/pages/depositPage2/depositPage.vue')
    },
    {
      path: 'member/deposit/confirmdeposit',
      name: 'member/deposit/confirmdeposit',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/depositPage/confirmDepositPage.vue')
    },
    {
      path: 'member/withdraw',
      name: 'member/withdraw',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => unavailableTime() ? import('@/pages/unavailablePage/unavailablePage.vue') : import('@/pages/withdrawPage/withdrawPage.vue')
    },
    {
      path: 'member/unavailablewithdraw',
      name: 'member/unavailablewithdraw',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/withdrawPage/unavailableWithdrawPage.vue')
    },
    {
      path: 'member/settings/bank',
      name: 'member/settings/bank',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/settingBankPage/settingBankPage.vue')
    },
    {
      path: 'member/vip',
      name: 'member/vip',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/vipPage/vipPage.vue')
    },
    {
      path: 'member/gem',
      name: 'member/gem',
      meta: {
        requiresAuth: true,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/vipPage/gemPage.vue')
    },
    {
      path: 'forgot-verify',
      name: 'forgot-verify',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/forgotPage/verifyPinPage.vue')
    },
    {
      path: 'reset',
      name: 'reset',
      meta: {
        requiresAuth: false,
        isLottoPage: false,
        isGamePage: false
      },
      component: () => import('@/pages/forgotPage/resetPassPage.vue')
    }
    ]
  }]
}

router.beforeEach((to, from, next) => {
  // console.log('isLottoPage: ' + to.matched.some(route => route.meta.isLottoPage))
  // console.log('isGamePermission: ' + (store.state.authModule.isGamePermission))
  const isGamePermission = Number(store.state.authModule.isGamePermission)
  // console.log('isGamePermission: ' + isGamePermission)
  // console.log('if 1: ' + (store.state.authModule.isGamePermission === '0' || store.state.authModule.isGamePermission === '1'))
  // console.log('if 2: ' + (store.state.authModule.isGamePermission === '0' || store.state.authModule.isGamePermission === '2'))
  if (to.matched.some(route => route.meta.requiresAuth) || (to.meta.requiresAuth === null)) {
    if (store.state.authModule.isMember || (to.meta.requiresAuth === null)) {
      if (to.matched.some(route => route.meta.isLottoPage)) {
        if (isGamePermission === 0 || isGamePermission === 1) {
          next()
        } else {
          next('/')
        }
      } else if (to.matched.some(route => route.meta.isGamePage)) {
        if (isGamePermission === 0 || isGamePermission === 2) {
          next()
        } else {
          next('/')
        }
      } else {
        next()
      }
    } else {
      console.log('------ to welcome 1')
      next('/welcome')
    }
  } else {
    if ((to.name === 'forgot-verify' || to.name === 'reset') && from.name === null) {
      console.log('------ to welcome 2')
      next('/welcome')
    } else {
      store.state.authModule.isMember && !store.state.registerModule.registerFlag
        ? next('/')
        : next()
    }
  }
})

export default router
